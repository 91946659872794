@use "../styles/theme.scss";

* {
  box-sizing: border-box;
}

html, body, :global(#root), :global(#ui-root), :global(.home-root) {
  margin: 0;
  height:100%;
}

body {
  // background: theme.$background1-color;
  background: rgba($color: #000000, $alpha: 0.0);
  color: theme.$text1-color;
}

:global(#root), :global(#ui-root), :global(.home-root) {
  display:flex; 
  flex-direction:column;
}

:global(.home-root) {
  background-size: cover;
  // background-image: url(../../assets/custom/images/SpookslotAchtergrond.jpg);

  @media(max-width: theme.$breakpoint-lg) {
    display: block;
  }
}

:global(#homepage-image) {
  position: fixed;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: -2;
}
:global(#homepage-video) {
  position: fixed;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

main {
  display: block;
  order: -1;
  flex: 1;

  @media(min-width: theme.$breakpoint-lg) {
    order: 0;
    display: flex;
    flex-direction: column;
  }
}
