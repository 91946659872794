@use "../styles/theme.scss";

:local(.home-page) {
  flex: 1;
  background-size: cover;
  padding: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: theme.$breakpoint-lg) {
    align-items: center;
    justify-content: flex-start;
  }

  & > section {
    margin: 2em auto;
  }
}

:local(.logo-container) {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 150px;

  img {
    width: 100%;
    align-self: flex-start;
  }

  @media (min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.sign-in-container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media(min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.mobile-sign-out) {
  padding: 0.5rem;
}

:local(.hero) {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;

  @media (min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
  @media (max-width: theme.$breakpoint-lg) {
    margin: 14px;
  }
}

:local(.heroBackground) {
  position: relative;
  padding: 20px;
  background: url('../../assets/svg/spookslot-pattern.svg?url');
  width: 100%;
  height: 100%;
}

:local(.heroBorder) {
  border-style: solid;
  border-width: 40px;
  border-image-source: url('../../assets/svg/Rand.svg?url');
  border-image-slice: 50;
  @media (max-width: theme.$breakpoint-lg) {
    border-image-source: url('../../assets/svg/RandMobile.svg?url');
  }
}

:local(.bat1) {
  position: absolute;
  bottom: 140px;
  right: 80px;
  width: 59px;
  height: 38px;
  background-image: url('../../assets/custom/images/vleermuis1.png');

  @media (max-width: theme.$breakpoint-lg) {
    right: 60px;
    bottom: 80px;
  }
}

:local(.bat2) {
  position: absolute;
  top: 80px;
  left: 107px;
  width: 38px;
  height: 35px;
  background-image: url('../../assets/custom/images/vleermuis2.png');

  @media (max-width: theme.$breakpoint-lg) {
    top: 35px;
  }
}

:local(.heroheader) {
  font-size: 44px;
  font-weight: 400;
  padding-bottom: 30px;
  letter-spacing: 4px;
  text-align: center;
  margin: 58px 0 32px;

  @media (max-width: theme.$breakpoint-lg) {
    font-size: 20px;
    margin: 24px 0 20px;
  }
}

:local(.herotext) {
  font-size: 20px;
  text-align: center;
  margin-bottom: 56px;
  // line-height: 19.38px;
  letter-spacing: 0.05rem;

  @media (max-width: theme.$breakpoint-lg) {
    font-size: 16px;
  }
}

:local(.buttonContainer) {
  text-align: center;

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 101px;
  }
}

:local(.app-info) {
  margin-bottom: 20px;
  text-align: center;

  & > * {
    margin-bottom: 20px;
  }

  @media (min-width: theme.$breakpoint-lg) {
    margin-bottom: 0;

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

:local(.app-description) {
  white-space: pre-wrap;
  align-self: auto;
  font-size: theme.$font-size-lg;
  font-weight: theme.$font-weight-medium;
  text-align: center;
  //margin: 0 24px 20px 24px;
  line-height: 1.25;
  width: 100%;

  @media (min-width: theme.$breakpoint-lg) {

  }
}

:local(.center-logo) {
  align-self: auto;
  margin: 24px 0;
}

:local(.cta-buttons) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:local(.features) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(min-width: theme.$breakpoint-lg) {
      margin-right: 24px;
      margin-bottom: 0;
      flex-direction: row;
      max-width: 364px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  img {
    width: 100%;
    border-radius: 12px;
  }
}

:local(.rooms-container) {
  margin-top: 120px !important;
  display: flex;
  flex-direction: column;
}

:local(.rooms-heading) {
  margin-left: 40px;
  font-size: 24px;
  margin-bottom: 16px;
}

:local(.rooms) {
  background-color: theme.$background2-color;
  border-radius: 16px;
}

:local(.row) {
  display: flex;
  justify-content: space-evenly !important;
}

:local(.col-lg) {
  flex-direction: column;

  @media(min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.home-page) :local(.card) {
  background-color: theme.$background2-color;
  border-radius: 16px;
  font-weight: theme.$font-weight-medium;
  padding-bottom: 32px !important;
  flex: 1;
}

:local(.center-lg) {
  @media(max-width: theme.$breakpoint-lg) {
    text-align: center;
    align-items: center;
  }
}
