@use "../styles/theme.scss";

footer {
  display: block;
  //background-color: theme.$background2-color;
  font-size: theme.$font-size-md;
  padding: 20px;

  @media(min-width: theme.$breakpoint-lg) {
    padding: 0;
    display: flex;
  }

  nav {
    display: flex;

    @media(min-width: theme.$breakpoint-lg) {
      flex: 1;
      justify-content: flex-end;
    }


    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;
      flex-direction: column;

      @media(min-width: theme.$breakpoint-lg) {
        flex-direction: row;
      }
    }

    a {
      color: theme.$text1-color;
      font-weight: bold;
    }

    li {
      display: flex;
      padding-top: 20px;
      align-items: center;

      @media(min-width: theme.$breakpoint-lg) {
        padding-top: 0;
        margin-right: 20px;
      }
    }
  }
}

:local(.container) {
  flex-direction: column;
  align-items: center;

  @media(min-width: theme.$breakpoint-lg) {
    height: 80px;
    flex-direction: row;
    align-items: inherit;
  }
}

:local(.powered-by) {
  text-align: center;
  color: theme.$text1-color;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media(min-width: theme.$breakpoint-lg) {
    margin-left: 20px;
  }
}

:local(.company-logo) {
  height: 49px;

  @media(min-width: theme.$breakpoint-lg) {
    margin-left: 18px;
    height: 41px;
  }
}

:local(.discord-logo) {
  height: 24px;
  width: 24px;
  display: inline;
  margin-right: 4px;
}
